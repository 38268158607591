<template>
  <div class="container">
    <div class="content">
      <div class="logo"><img src="../assets/images/logo.png" /></div>
      <div class="input-item">
        <img src="../assets/images/email-icon.png" />
        <input v-model="email" type="text" placeholder="请输入邮箱" autocomplete="off" />
      </div>
      <div class="input-item">
        <img src="../assets/images/vcode-icon.png" />
        <input v-model="vcode" type="text" placeholder="请输入验证码" autocomplete="off" />
        <div class="btn" :class="time > 0 ? 'not' : ''" @click="handleGetVCode">{{ time > 0 ? time + 's' : '获取验证码' }}</div>
      </div>
      <div class="submit-btn" @click="handleRegister">立即注销</div>
    </div>
  </div>
</template>
<script>
import request from '@/utils/request'

export default {
  name: 'Logout',
  data() {
    return {
      email: '',
      vcode: '',
      time: 0,
      timer: null,
      loading: false
    }
  },
  mounted() {

  },
  methods: {
    handleGetVCode() {
      const  { email, time, loading } = this
      if (time > 0 || loading) {
        return
      }
      this.loading = true
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
      if (!regEmail.test(email)) {
        this.$toast('请输入正确的邮箱')
        return
      }
      const api = 'api/sendMsg'
      const data = {
        email
      }
      request({
        url: api,
        method: 'post',
        data
      }).then(res => {
        this.loading = false
        if (res.error == 0) {
          this.handleSetTimer()
        }
        this.$toast(res.info)
      })
    },
    handleSetTimer() {
      let time = 60
      this.time = time
      this.timer = setInterval(() => {
        time--
        if (time <= 0) {
          time = 0
          clearInterval(this.timer)
        }
        this.time = time
      }, 1000)
    },
    handleRegister() {
      const { email, vcode, loading } = this
      if (loading) {
        return
      }
      this.loading = true
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
      if (!regEmail.test(email)) {
        this.$toast('请输入正确的邮箱')
        return
      }
      if (!vcode) {
        this.$toast('请输入验证码')
        return
      }
      const api = 'api/zx'
      const data = {
        email,
        vcode: vcode,
      }
      request({
        url: api,
        method: 'post',
        data
      }).then(res => {
        this.loading = false
        this.$toast(res.info)
        if (res.error == 0) {
          this.email = ''
          this.vcode = ''
          this.time = 0
          if (this.timer) {
            clearInterval(this.timer)
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 10;
    .logo {
      width: 1rem;
      height: 1rem;
      margin-bottom: 1rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .input-item {
      width: 5.4rem;
      height: .8rem;
      background-color: #fff;
      border-radius: .4rem;
      margin-bottom: .3rem;
      padding: 0 .3rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: .4rem;
        height: .4rem;
      }
      input {
        flex: 1;
        margin: 0 .3rem;
        font-size: .28rem;
      }
      .btn {
        font-size: .26rem;
        color: #0066e1;
        &.not {
          color: #999;
        }
      }
    }
    .agreement {
      width: 5.6rem;
      font-size: .24rem;
      display: flex;
      align-content: center;
      color: #333;
      .dot {
        width: .18rem;
        height: .18rem;
        padding: .04rem;
        border: 1px solid #333;
        margin-right: .2rem;
        border-radius: .04rem;
        .foc {
          width: 100%;
          height: 100%;
          background-color: #0066e1;
          border-radius: .04rem;
        }
      }
      a {
        color: #0066e1;
      }
    }
    .submit-btn {
      width: 6rem;
      height: .8rem;
      border-radius: .4rem;
      text-align: center;
      line-height: .8rem;
      background-color: #fff;
      color: #1a1a1a;
      font-size: .3rem;
      margin-top: .8rem;
    }
  }
}

</style>
